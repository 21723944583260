/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-05-07 16:20:32
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-08 00:59:57
 */
import _axios from './axios_init.js';

const install = (app) => {
   
  app.config.globalProperties.$http = _axios;
};

export default { install };
