/*
 * @Description: 配置参数
 * @Author: 琢磨先生
 * @Date: 2022-05-09 21:46:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-12-14 16:17:21
 */

export default {
  /**
   * api请求host地址
   */
  HOST:
    process.env.NODE_ENV === "development"
      ? "http://localhost:7000"
      : "https://api.baobei.dezhaiyoujia.com",
  /**
   * 本地缓存token名称
   */
  TOKEN: "token",

  /**
   * 应用编码
   */
  APP_CODE: "admin",
  /**
   * 经纪人编码
   */
  AGENT_CODE: "agent",
  /**
   * 项目app key
   */
  APP_KEY: "dyb_admin_pc",
  /**
   * 接口KEY
   */
  API_KEY: "dyb_admin_api",
  /**
   * API权限数据本地存储Key
   */
  API_STORE_KEY: "api_store_key",
};
