import http from "./axios_init.js";

export default {
  /**
   * 获取个人资料
   * @returns
   */
  get_center() {
    return http.get("admin/v1/center");
  },

  /**
   * 获取菜单数据
   */
  get_menus() {
    return http.get("admin/v1/center/vue_route");
  },
  /**
   * 获取接口数据
   */
  get_apis() {
    return http.get("admin/v1/center/apis");
  },

  /**
   * 分部
   */
  get_attrs() {
    return http.get("admin/v1/center/attrs");
  },
};
