/*
 * @Description: 状态管理
 * @Author: 琢磨先生
 * @Date: 2022-05-07 20:48:29
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-28 12:00:21
 */
import { createStore } from "vuex";

const store = createStore({
  state: {
    //我的个人资料
    my_info: null,
    settings: null,
    apis: [],
  },
  getters: {
    /**
     * 获取我的个人资料
     * @param {*} state
     */
    getMy(state) {
      if (!state.my_info) {
        var json = localStorage.getItem("my_info");
        if (json) {
          state.my_info = JSON.parse(json);
        }
      }
      return state.my_info;
    },

    /**
     * 获取配置参数
     * @param {*} state
     * @returns
     */
    getSettings(state) {
      if (!state.settings) {
        var json = localStorage.getItem("settings");
        if (json) {
          state.settings = JSON.parse(json);
        }
      }
      return state.settings;
    },

    /**
     * 获取api权限数据
     * @param {*} state
     */
    getApis(state) {
      if (!state.apis) {
        var json = localStorage.getItem("apis");
        if (json) {
          state.apis = JSON.parse(json);
        }
      }
      return state.apis;
    },
  },
  mutations: {
    /**
     * set存储个人信息
     * @param {*} state
     * @param {*} obj
     */
    setMy(state, obj) {
      state.my_info = obj;
      localStorage.setItem("my_info", JSON.stringify(obj));
    },
    /**
     * set存储配置参数
     * @param {*} state
     * @param {*} obj
     */
    setSettings(state, obj) {
      state.settings = obj;
      localStorage.setItem("settings", JSON.stringify(obj));
    },

    /**
     * set存储个人api权限接口数据
     * @param {*} state
     * @param {*} obj
     */
    setApis(state, obj) {
      state.apis = obj;
      localStorage.setItem("apis", JSON.stringify(obj));
    },
  },
});

export default store;
