/*
 * @Description: 配置注入app
 * @Author: 琢磨先生
 * @Date: 2022-05-09 21:50:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-09 21:57:16
 */

import config from "@/config/config";

const install = (app) => {
  /**
   * 全局配置
   */
  app.config.globalProperties.$cfg = config;
};

export default { install };
