<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-02-18 11:00:31
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-18 11:02:00
-->
<template>
    <router-view></router-view>
  </template>
  
  <script>
  export default {
    watch: {
      /**
       * 监听路由变化
       */
      $route() {
        this.getBreadcrumb();
      },
    },
    created() {
      this.getBreadcrumb();
    },
    methods: {
      /**
       * 获取breadcrumb
       */
      getBreadcrumb() {
        if (
          this.$route.matched &&
          Object.keys(this.$route.matched[0].meta).length > 0
        ) {
          this.breadList = this.$route.matched;
        } else {
          this.breadList = [];
        }
      },
    },
  };
  </script>