/*
 * @Description: 工具类
 * @Author: 琢磨先生
 * @Date: 2022-05-17 11:23:48
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-17 11:25:57
 */

const install = (app) => {
  /**
   *
   */
  app.config.globalProperties.$utils = {

    /**
     * 验证是否是手机号码
     * @param {*} input 
     * @returns 
     */
    is_phone(input) {
      var reg = /^1[23456789]\d{9}$/gi;
      return reg.test(input);
    },
  };
};

export default { install };
