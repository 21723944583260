<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-07 22:13:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-13 09:48:48
-->

<template>
  <el-scrollbar>
    <el-menu
      :router="true"
      active-text-color="#000000"
      background-color="#f2f2f2"
      :default-active="meta.id"
    >
      <el-menu-item index="0" :route="{ path: '/dashboard' }">
        <el-icon> <house /> </el-icon>首页
      </el-menu-item>
      <sub-menu
        v-for="item in menu"
        :key="item"
        :father="item"
        :menus="item.children"
      ></sub-menu>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import SubMenu from "./submenu";

export default {
  name: "SideBar",
  components: {
    SubMenu,
  },
  data() {
    return {
      menu: [],
      meta: {},
    };
  },
  props: ["routes"],
  created() {
    var json = localStorage.getItem(this.$cfg.CURRENT_META_KEY);
    if (json) {
      this.meta = JSON.parse(json);
    } else {
      this.meta = {
        id: "0",
      };
    }
  },
  watch: {
    routes: {
      handler(arr) {
        this.menu = arr;
      },
      immediate: true,
    },
  },
};
</script>

<style>
.el-aside .el-menu li {
  border-bottom: 1px solid #e5e5e5;
}

.el-aside .el-sub-menu.is-active .el-sub-menu__title {
  font-weight: bold;
  color: #2b7dbc;
}

.skin .el-aside .el-sub-menu .el-menu {
  background: white;
  position: relative;
}
.skin .el-aside .el-sub-menu .el-menu::before {
  display: block;
  content: "";
  border-left: 1px dotted #9dbdd6;
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.skin .el-aside .el-sub-menu .el-menu li {
  position: relative;
  border-bottom: none;
  border-top: 1px dotted #e5e5e5;
}

.skin .el-aside .el-sub-menu .el-menu li::before {
  display: block;
  content: "";
  border-top: 1px dotted #9dbdd6;
  position: absolute;
  left: 20px;
  z-index: 1;
  width: 7px;
}

.skin .el-menu-item.is-active {
  color: #2b7dbc;
  background: #f5f7fa;
}

.skin .el-aside li.el-menu-item.is-active::before {
  display: block;
  content: "";
  position: absolute; 
  width: 0;
  height: 0;
  border: 5px solid;
  border-color: transparent transparent transparent #c86139;
}
</style>
